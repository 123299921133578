@font-face {
    font-family: "SofiaPro";
    font-weight: 200;
    src: url("../fonts/SofiaProExtraLight.woff2") format("woff2"),
         url("../fonts/SofiaProExtraLight.woff") format("woff"),
         url("../fonts/SofiaProExtraLight.otf") format('opentype');
    font-display: swap;
}

@font-face {
    font-family: "Lucida";
    src: url("../fonts/LucidaGrande.woff2") format("woff2"),
         url("../fonts/LucidaGrande.woff") format("woff"),
         url("../fonts/LucidaGrande.ttf") format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "LaCartoonerie";
    font-weight: 200;
    src: url("../fonts/laCartoonerie.woff2") format("woff2"),
         url("../fonts/laCartoonerie.woff") format("woff"),
         url("../fonts/laCartoonerie.ttf") format('truetype');
    font-display: swap;
}
