@import "./fonts.scss";
@import "./variables.scss";
@import "./loader.scss";

* {
    box-sizing: border-box;
}

[hidden] {
    display: none !important;
}

a {
    text-decoration: none;
}

button {
    border: none;
    cursor: pointer;
    background-color: transparent;
}

p {
    line-height: normal;
}

input {
    padding: 0;
    margin: 0;
}

body {
    font-family: $font-lucida;
    font-size: 16px;
    color: $color-gold-dark;
    background-color: $color-dark;
    overflow-x: hidden;
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.container {
    max-width: 1140px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

/*slider*/
.slick-list,
.slick-slider {
    position: initial;
    display: block;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots {
    li {
        position: relative;
        display: inline-block;
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
        transition: 500ms;
    }
    button {
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
        color: transparent;
        border: 0;
        outline: none;
        background: #e0e0e0;
    }
    .slick-active {
        button {
            background: #000;
        }
    }
}

.slick-slide {
    float: left;
    font-size: 0;
}
