.bg-top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1160px;
    z-index: -5;
}
.bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -5;
}

.title-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    max-width: 386px;
    margin: 0 auto 13px;
    @media screen and (max-width: 780px) {
        padding-right: 30px;
    }
}

/* main page */
.main {
    &-page {
        position: relative;
        min-height: 100vh;
        height: 100%;
        box-sizing: border-box;
        padding-top: 0;
        padding-bottom: 20px;
        .bg-bottom {
            display: none;
            @media screen and (max-width: 780px) {
                display: block;
            }
        }
    }
    &-title {
        font-family: $font-oscar;
        font-size: 60px;
        font-weight: 200;
        // display: flex;
        // flex-direction: column;
        // align-items: flex-end;
        // line-height: 0.6;
        display: inline-block;
        text-align: right;
        letter-spacing: 1.33px;
        background-image: $bg-gold-gradient;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        span {
            font-size: 24px;
            // display: block;
        }
    }
    &-subtitle {
        flex-shrink: 0;
        margin-left: 3px;
        margin-bottom: 4px;
    }
    &-text {
        line-height: 1.38;
        text-align: center;
        max-width: 700px;
        margin: 0 auto;
    }
}

.block-help {
    position: absolute;
    span {
        font-family: $font-cartoon;
        font-size: 20px;
        color: $color-white;
        display: inline-block;
        @media screen and (max-width: 780px) {
            font-size: 16px;
        }
    }
}

.slide1 {
    .block-help1 {
        top: 25px;
        right: 0px;
        display: flex;
        flex-direction: row-reverse;
        svg {
            position: relative;
            top: 13px;
            height: 60px;
            transform: scale(-1, 1);
            margin-left: 16px;
        }
        span {
            text-align: right;
            transform: rotate(-3deg);
            max-width: 130px;
        }
    }
    .block-help2 {
        display: flex;
        flex-direction: row-reverse;
        bottom: 10px;
        right: 25px;
        svg {
            transform: scale(-1, 1);
            position: relative;
            top: -20px;
            height: 30px;
            margin-left: 10px;
        }
        span {
            transform: rotate(5deg);
        }
    }
    .block-help3 {
        top: 50%;
        left: -40px;
        display: flex;
        flex-direction: column;
        svg {
            transform: scale(-1, 1);
            height: 35px;
            margin-bottom: 10px;
        }
        span {
            text-align: center;
            transform: rotate(10deg);
            max-width: 110px;
        }
    }
}

.slide2 {
    .block-help1 {
        top: 25px;
        left: 25px;
        display: flex;
        svg {
            position: relative;
            top: 13px;
            height: 60px;
            margin-right: 16px;
        }
        span {
            text-align: left;
            transform: rotate(3deg);
            max-width: 130px;
        }
    }
    .block-help2 {
        display: flex;
        flex-direction: row-reverse;
        bottom: 10px;
        right: 90px;
        svg {
            transform: scale(-1, 1);
            position: relative;
            top: -20px;
            height: 30px;
            margin-left: 10px;
        }
        span {
            transform: rotate(5deg);
        }
    }
    .block-help3 {
        top: 42%;
        right: -40px;
        display: flex;
        flex-direction: column;
        svg {
            height: 35px;
            margin-bottom: 10px;
        }
        span {
            text-align: center;
            transform: rotate(-10deg);
            max-width: 110px;
        }
    }
}

.slide3 {
    .block-help1 {
        bottom: 0px;
        right: 39px;
        display: flex;
        flex-direction: row-reverse;
        svg {
            position: relative;
            top: -30px;
            height: 50px;
            transform: scale(-1, -1);
            margin-left: 16px;
        }
        span {
            text-align: right;
            transform: rotate(5deg);
            max-width: 130px
        }
    }
    .block-help2 {
        display: flex;
        top: 46px;
        left: 100px;
        svg {
            transform: scale(1, -1);
            position: relative;
            top: 3px;
            height: 50px;
            margin-right: 10px;
        }
        span {
            transform: rotate(5deg);
        }
    }
    .block-help3 {
        top: 46%;
        left: -40px;
        display: flex;
        flex-direction: column;
        svg {
            height: 35px;
            transform: scale(-1, 1);
            margin-bottom: 10px;
        }
        span {
            text-align: center;
            transform: rotate(10deg);
            max-width: 110px;
        }
    }
}

.slide4 {
    .block-help1 {
        top: 25px;
        left: 25px;
        display: flex;
        svg {
            position: relative;
            top: 13px;
            height: 60px;
            margin-right: 16px;
        }
        span {
            text-align: left;
            transform: rotate(3deg);
            max-width: 130px;
        }
    }
    .block-help2 {
        display: flex;
        bottom: 0px;
        left: 10px;
        svg {
            position: relative;
            top: -42px;
            height: 50px;
            margin-right: 10px;
        }
        span {
            transform: rotate(5deg);
        }
    }
    .block-help3 {
        top: 52%;
        right: -40px;
        display: flex;
        flex-direction: column;
        svg {
            height: 35px;
            margin-bottom: 10px;
        }
        span {
            text-align: center;
            transform: rotate(-10deg);
            max-width: 110px;
        }
    }
}

.main-slider {
    img {
        width: 100%;
        border-radius: 36px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 8px;
    }
    .slick-slide {
        opacity: .2;
        transition: .6s;
        position: relative;
    }
    .slick-active {
        transition: .6s;
        opacity: .6;
        filter: blur(5px);
        @media screen and (max-width: 780px) {
            opacity: 1;
            filter: none;
        }
    }
    .slick-current {
        opacity: 1;
        filter: none;
    }

    .slider-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 50;
        svg {
            width: 60px;
        }
    }
    .slider-arrow-right {
        right: 30px;
    }
    .slider-arrow-left {
        left: 30px;
    }

    .slide-container {
        position: relative;
        max-width: 405px;
        padding-top: 93px;
        padding-bottom: 71px;
        margin: 0 auto;
        @media screen and (max-width: 780px) {
            max-width: 300px;
            padding-top: 71px;
            padding-bottom: 57px;
        }
    }
    .slide {
        position: relative;
        max-width: 405px;
        margin: 0 auto;
        &:after {
            content: "";
            display: block;
            width: 100%;
            padding-top: 100%;
        }
        @media screen and (max-width: 780px) {
            max-width: 300px;
        }
    }
}

/* create-page */
.create-page {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .bg-bottom {
        display: none;
        @media screen and (max-width: 780px) {
            display: block;
        }
    }
}

.step {
    &-title {
        font-size: 24px;
        color: $color-gold-middle;
        line-height: 1.38;
        text-align: center;
    }
    &-text {
        font-size: 20px;
        color: $color-gold-middle;
        text-align: center;
        line-height: 1.38;
        margin-bottom: 30px;
        span {
            color: $color-gold-dark;
            display: block;
        }
        @media screen and (max-width: 780px) {
            font-size: 16px;
        }
    }
}

/* processing page */
.crop-container {
    max-width: 600px;
    width: calc(100% + 30px);
    height: 70%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    button {
        font-size: 20px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: -130px;        
        width: 300px;
        height: 60px;
        border-radius: 30px;
        box-shadow: 30px 40px 40px 0 rgba(21, 22, 23, 0.1);
        background-image: $bg-red-gradient-circle;
        span {
            color: $color-gold-light;
        }
        svg {
            display: none;
            width: 16px;
        }
        @media screen and (max-width: 780px), all and (max-width: 1024px) and (orientation: landscape) {
            left: auto;
            right: 24px;
            bottom: 24px;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            svg {
                display: block;
            }
            span {
                display: none;
            }
        }
    }
    @media screen and (max-width: 780px) {
        left: -15px;
        transform: none;
        margin: 0 auto;
    }
}

.processing-page {
    position: relative;
    min-height: 100vh;
    height: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
    padding-top: 32vh;
    &_step2 {
        height: auto;
        min-height: 100vh;
        &.processing-page-open {
            padding-top: 2vh;
        }
    }
    &_crop {
        padding-top: 25px;
        h2 {
            font-size: 24px;
            color: $color-gold-middle;
            line-height: 1.6;
            text-align: center;
            position: relative;
            z-index: 10;
        }
        p {
            color: $color-gold-dark;
            line-height: 1.6;
            text-align: center;
            margin-bottom: 25px;
        }
        @media screen and (max-width: 780px) {
            h2 {
                font-size: 16px;
                margin-bottom: 25px;
            }
            p {
                display: none;
            }
        }
    }
    .container {
        width: 100%;
    }
    .btns-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        right: 0;
        bottom: 10%;
        margin-top: 10px;
        @media screen and (max-width: 780px) {
            position: fixed;
            margin: 0;
        }
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin-left: 24px;
        }
        svg {
            width: 24px;
        }
        @media screen and (max-width: 780px) {
            justify-content: flex-end;
            right: 16px;
            bottom: 10px;
        }
    }
    .bg-bottom {
        display: none;
        @media screen and (max-width: 780px) {
            display: block;
        }
    }
}

.select-device-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    button {
        margin-top: 16px;
    }
}

.suggestion {
    &-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 28px;
    }
    &-item {
        font-size: 14px;
        color: $color-white;
        border-radius: 100px;
        border: solid 1px $color-white;
        padding: 12px 16px;
        margin-right: 8px;
        margin-bottom: 8px;
    }
}

.select {
    &-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2px;
        width: calc(100% + 30px);
        position: relative;
        left: -15px;
        margin-top: 28px;
    }
    &-item {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
        font-size: 20px;
        color: $color-gold-dark2;
        margin-bottom: 12px;
        @media screen and (max-width: 780px) {
            font-size: 16px;
        }
    }
    &-area {
        font-size: 14px;
        color: $color-grey-light;
        width: 100%;
        height: 40px;
        background-color: transparent;
        border-radius: 20px;
        border: solid 1px $color-grey;
        padding: 0 16px;
        &:valid  {
            color: $color-white;
            border-color: $color-white;
        }
        &:focus {
            color: $color-white;
            border-color: $color-white;
        }
        @media screen and (max-width: 780px) {
            height: 28px;
            border-radius: 14px;
        }
    }
    &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 500px;
        width: 100%;
        @media screen and (max-width: 780px) {
            max-width: 334px;
        }
    }
    &-input-container {
        position: relative;
        width: 100%;
    }
    .form-btn-search {
        font-family: $font-lucida;
        font-size: 20px;
        color: $color-white;
        width: 96px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 20px;
        background-image: $bg-red-gradient;
        margin-left: 8px;
        @media screen and (max-width: 780px) {
            display: none;
        }
    }
    .form-btn-cancel {
        padding: 6px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        svg {
            width: 9px;
        }
    }
}

/* result page */
.result {
    &-page {
        padding-top: 24px;
        .container {
            max-width: 530px;
        }
        .title-container {
            max-width: initial;
            margin: 0 auto 27px;
            .main-title {
                font-size: 70px;
            }
            .main-subtitle {
                font-size: 34px;
            }
            @media screen and (max-width: 780px) {
                display: none;
            }
        }
        .btns-container {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            position: relative;
            max-width: 530px;
            margin: 0 auto;
            padding: 14px 18px 0;
            @media screen and (max-width: 780px) {
                flex-direction: row;
                padding-top: 40px;
            }
        }
        .btn-try {
            font-size: 20px;
            color: $color-gold-light;
            text-transform: uppercase;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 327px;
            height: 60px;
            border-radius: 30px;
            border: solid 2px $color-gold-light;
            svg {
                position: absolute;
                left: 36px;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
                margin-bottom: 8px;
            }
            @media screen and (max-width: 780px) {
                font-size: 10px;
                width: auto;
                height: auto;
                flex-direction: column;
                position: static;
                transform: none;
                border: none;
                svg {
                    position: static;
                    transform: none;
                }
            }
        }
        .btn-download {
            font-size: 20px;
            color: $color-gold-light;
            text-transform: uppercase;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 327px;
            height: 60px;
            background-image:  $bg-red-gradient-circle;
            border-radius: 30px;
            position: relative;
            padding: 0;
            margin-bottom: 20px;
            @media screen and (max-width: 780px) {
                font-size: 10px;
                flex-direction: column;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                background: none;
                max-width: 64px;
                height: auto;
                border-radius: 0;
                margin: 0;
            }
        }
        .btn-download-icon {
            position: absolute;
            left: 36px;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            svg {
                width: 24px;
            }
            @media screen and (max-width: 780px) {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: static;
                transform: none;
                width: 64px;
                height: 64px;
                background-image: $bg-red-gradient-circle;
                margin-bottom: 8px;
            }
        }
        .imageview-host {
            width: 100%;
            border-radius: 36px;
            box-shadow: 0 24px 120px 0 rgba(9, 9, 9, .2);
            margin-bottom: 16px;
            overflow: hidden;
        }
        .bg-top {
            display: block;
            @media screen and (max-width: 780px) {
                display: none;
            }
        }
        .bg-bottom {
            display: none;
            @media screen and (max-width: 780px) {
                display: block;
            }
        }
    }
}

.tabs-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    left: -110px;
    top: 0;
    margin-bottom: 16px;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 90px;
        border-radius: 18px;
        border: solid 1px $color-gold-dark;
        margin-bottom: 20px;
        &.tab--nomination_bonus_1 {
            position: relative;
            &:before {
                content: "";
                display: block;
                width: 45px;
                height: 23px;
                position: absolute;
                top: -8px;
                right: -8px;
                background-image: url("/assets/img/icons/ic-bonus.png");
                background-size: contain;
            }
        }
    }
    svg {
        height: 60px;
        fill: $color-gold-dark;
    }
    .active {
        border-width: 2px;
        border-color: $color-gold-light;
        svg {
            fill: $color-gold-light;
        }
    }
    @media screen and (max-width: 780px) {
        flex-direction: row;
        position: static;
        button {
            width: 54px;
            height: 54px;
            border-radius: 10px;
            margin-bottom: 0;
        }
        svg {
            height: 36px;
        }
    }
}

/*modal*/
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(33, 32, 32, 0.7);
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        z-index: -5;
    }
}

.modal {
    width: 90%;
    max-width: 366px;  
    border-radius: 36px;
    border: solid 2px $color-gold-dark2;
    background-color: $color-dark;
    padding: 60px 24px;
    &-title {
        font-size: 24px;
        line-height: 0.92;
        text-align: center;
        color: $color-gold-middle;
        margin-bottom: 16px;
    }
    &-text {
        line-height: 1.38;
        text-align: center;
        margin-bottom: 24px;
    }
    &-text-loader {
        line-height: 1.38;
        text-align: center;
        color: $color-grey-light;
        margin-bottom: 24px;
    }
    .btns-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% + 16px);
        position: relative;
        left: -8px;
        padding: 0;
        margin-bottom: 24px;
        button {
            margin: 0 8px;
        }
    }
    .btn-social {
        width: 56px;
        height: 56px;
        flex-shrink: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            height: 24px;
        }
    }
    .btn-fb {
        background-color: #1976d2;
    }
    .btn-insta {
        background-image: linear-gradient(41deg, #ffdd55 16%, #ff543e 51%, #c837ab 84%);
    }
    .btn-snap {
        background-color: #fffc00;
    }
    .btn-got-it {
        font-size: 16px;
        text-align: center;
        color: $color-gold-light;
        text-transform: uppercase;
        height: 56px;
        padding: 0 16px;
        border-radius: 28px;
        background-image: $bg-red-gradient;
    }
    .btn-download-again {
        color: $color-gold-light;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        margin: 0 auto;
        svg {
            width: 22px;
            margin-right: 16px;
        }
    }
}

/*error*/
.error-container {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-dark;
}

.error {
    width: 90%;
    max-width: 366px; 
    text-align: center;
}

.error-text {
    margin-bottom: 16px;
}

/*loader*/
.loader-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .bg-bottom {
        display: none;
        @media screen and (max-width: 780px) {
            display: block;
        }
    }
}

.loader {
    position: relative;
    width: 60px;
    height: 60px;
    display: inline-block;
    margin-bottom: 60px;
    &-text {
        font-size: 20px;
        width: 90%;
        text-align: center;
        @media screen and (max-width: 780px) {
            font-size: 16px;
        }
    }
    &-star {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}


.star-small {
    animation: star-small-pulse 1s ease-in-out alternate infinite;
}

.star-big {
    animation: star-big-pulse 2s -0.2s ease-in-out infinite;
}

@keyframes star-small-pulse {
    0% {
        transform: translate(-50%,-50%) scale(1);
    }
    100% {
        transform: translate(-50%,-50%) scale(2);
    }
}

@keyframes star-big-pulse {
    0% {
        transform: translate(-50%,-50%) scale(0);
        opacity: 1;
    }
    100% {
        transform: translate(-50%,-50%) scale(5);
        opacity: 0;
    }
}

.fetch-message {
    text-align: center;
    margin-top: 16px;
}

.result-container {
    position: relative;
}

.creative-container {
    position: relative;
    max-width: 530px;
    width: 100%;
    flex-shrink: 0;
    margin: 0 auto;
}

.creative-loader {
    z-index: -5;
    &.loader-container {
        position: absolute;
    }
    .loader {
        margin: 0;
    }
    .loader-text,
    .bg-top,
    .bg-bottom {
        display: none;
    }
}

/*btn*/
.btn-select {
    font-size: 20px;
    color: $color-gold-light;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 300px;
    height: 60px;
    border-radius: 72px;
    background-image: $bg-red-gradient;
    margin: 0 auto;
    @media screen and (max-width: 780px) {
        font-size: 16px;
        width: 254px;
        height: 50px;
    }
}

@media all and (max-width: 396px) {
    .modal {
        .btns-container button {
            margin: 0 6px;
        }
        .btn-social {
            width: 44px;
            height: 44px;
            svg {
                height: 20px;
            }
        }
        .btn-got-it {
            font-size: 14px;
            height: 44px;
            padding: 0px 10px;
        }
    }
}

@media all and (max-height: 640px), all and (max-width: 380px) {
    .main-slider .slide {
        max-width: 240px;
    }
    .main-title {
        font-size: 50px;
    }
    .main-text {
        font-size: 14px;
    }
    .slide-container {
        padding-top: 60px;
        padding-bottom: 50px;
    }
}

@media all and (max-height: 610px) and (max-width: 370px) {
    .main-title {
        font-size: 36px;
        span {
            font-size: 16px;
        }
    }
    .title-container {
        max-width: 300px;
        margin: 0 auto 6px; 
    }
    .main-text {
        font-size: 12px;
    }
    .main-slider {
        .slide-container {
            max-width: 240px;
        }
        .slide {
            max-width: 180px;
        }
    }
    .slick-slider {
        margin-top: -16px;
    }
    .btn-select {
        font-size: 14px;
        width: 220px;
        height: 40px;
    }
    .main-page {
        padding: 0;
        .btn-select {
            margin-top: -10px;
        }
    }
    .result-page {
        padding-top: 16px;
    }
    .tabs-btns button {
        width: 40px;
        height: 40px;
        border-radius: 8px;
    }
    .tabs-btns svg {
        height: 24px;
    }
    .creative-container {
        max-width: 240px;
    }
}

@media all and (max-width: 1024px) and (orientation: landscape) {
    .result-page {
        .container {
            max-width: 420px;
        }
        .title-container {
            display: none;
        }
    }
}

@media all and (max-height: 500px) and (orientation: landscape) {
    .result-page {
        padding-top: 10px;
    }
    .creative-container {
        max-width: 240px;
    }
}

@media all and (max-height: 920px) and (min-width: 780px) {
    .creative-container {
        max-width: 400px;
    }
    .tabs-btns {
        left: -60px;
    }
    .processing-page_crop {
        padding-bottom: 25px;
    }
    .result-page .container {
        max-width: 530px;
    }
}

@media all and (max-height: 830px) and (min-width: 780px) {
    .main-slider .slide {
        max-width: 300px;
    }
    .creative-container {
        max-width: 300px;
    }
    .tabs-btns {
        left: 0;
        button {
            width: 54px;
            height: 54px;
        }
        svg {
            height: 30px;
        }
    }
    .crop-container {
        height: 60%;
    }
}

@media all and (max-height: 730px) and (min-width: 780px) {
    .main-slider .slide {
        max-width: 240px;
    }
    .creative-container {
        max-width: 240px;
    }
}

@media all and (max-width: 360px) and (max-height: 560px) {
    .main-slider .slide {
        max-width: 150px;
    }
}
