$color-dark: #232222;
$color-white: #fff;
$color-gold-dark: #baa04d;
$color-gold-dark2: #aa9248;
$color-gold-light: #f0e2a1;
$color-gold-middle: #d4c074;
$color-grey: #6e6e6e;
$color-grey-light: #868686;

$bg-gold-gradient: linear-gradient(116deg, #a68e40 4%, #e1c769 27%, #fefbd7 64%, #c5ac53 88%, #715725 95%);
$bg-red-gradient: linear-gradient(102deg, #98130a 5%, #da0404 46%, #981515 94%);
$bg-red-gradient-circle: linear-gradient(138deg, #98130a 2%, #da0404 46%, #981515 97%);

$font-oscar: "SofiaPro", sans-serif;
$font-lucida: "Lucida", sans-serif;
$font-cartoon: "LaCartoonerie", sans-serif;
